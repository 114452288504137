<template>
  <div>
    <v-app>
      <div id="nav">
        <h1 style="font-weight: 500" class="mt-10 mb-n10">ABLE Members Hub</h1>
        <img
          class="able-transparent"
          height="400px"
          src="/img/able-icon-white.png"
          alt="ABLE Icon"
        />
      </div>

      <v-carousel
        cycle
        :show-arrows="false"
        hide-delimiters
        :continuous="true"
        height="100%"
        interval="10000"
        style="position: fixed; left: 0; top: 0; z-index: 0; overflow: hidden;"
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>

      <v-main>
        <div class="home">
          <template>
            <v-container id="landing-container">
              <v-row no-gutters class="d-flex justify-center align-end">
                <v-col
                  class="white--text text-center mx-auto"
                  cols="12"
                  sm="12"
                  xs="4"
                  offset-xs="8"
                  style="z-index: 1"
                >
                  <v-row justify="center">
                    <v-col lg="4" md="6" cols="11">
                      <v-card>
                        <div class="d-none">
                        <!-- <div> -->
                          <h3>Maintanance</h3>
                          <p>We will be up as soon as possible.</p>
                        </div>
                        <!-- <div class="d-none"> -->
                        <div>
                          <v-card-title class="headline text-center">
                            Login
                          </v-card-title>
                          <v-card-text class="text-left">
                            <v-row>
                              <v-col style="overflow: hidden">
                                <GoogleSignIn />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <FacebookLogin />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                        <v-row class="d-none">
                          <v-col>
                            <v-btn elevation="2" @click="fakeLoginAdmin"
                              >Fake Login Admin</v-btn
                            >
                            <v-btn elevation="2" @click="fakeLoginMember"
                              >Fake Login Member</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col md="2">
                      <router-link to="/privacy">Privacy Policy</router-link>
                    </v-col>
                    <v-col md="2">
                      <router-link to="/terms">Terms and Conditions</router-link>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import GoogleSignIn from "@/components/home/GoogleSignIn.vue";
import FacebookLogin from "@/components/home/FacebookLogin.vue";

export default {
  name: "Login",
  components: {
    GoogleSignIn,
    FacebookLogin,
  },
  data() {
    return {
      btnColor: "#1ec3f0",
      outlined: false,
      elevation: 5,
      items: [
        { src: "/img/able-background.jpg" },
        { src: "/img/able-community1.jpg" },
        { src: "/img/able-community2.jpg" },
        { src: "/img/able-community3.jpg" },
      ],
    };
  },
  methods: {
    fakeLoginAdmin() {
      this.$store.commit("login", {
        name: "Georgi Kirilov",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14Gid6mqa4B0kNFmt5sdEWqcJBuaLCCkpWLpihnou=s96-c",
        type: "google",
        subscription: false,
        failedPayment: true,
        role: "admin",
        expirationDate: new Date(
          new Date().getTime() + 3 * 3600 * 1000
        ).toString(),
      });
      this.$router.push("/");
    },
    fakeLoginMember() {
      this.$store.commit("login", {
        name: "Georgi Kirilov",
        avatar:
          "https://lh3.googleusercontent.com/a-/AOh14Gid6mqa4B0kNFmt5sdEWqcJBuaLCCkpWLpihnou=s96-c",
        type: "google",
        subscription: false,
        failedPayment: true,
        role: "member",
        expirationDate: new Date(
          new Date().getTime() + 3 * 3600 * 1000
        ).toString(),
      });
      this.$router.push("/");
    },
  },
  created() {
    if (!this.$store.getters.isAuthenticated) {
      return;
    }

    // this.$router.replace('/')
  },
};
</script>

<style scoped>
#app {
  /*background: lightblue url("/img/able-background.jpg") no-repeat fixed center;*/
}

#nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /*height: 100px;*/
  opacity: 0.9;
  text-align: center;
  color: white;
  padding: 10px 0;
  background-image: linear-gradient(
    to bottom,
    rgba(31, 195, 243, 1),
    rgba(31, 195, 243, 0)
  );
  z-index: 1;
}

.able-transparent {
  opacity: 0.8;
}
.home {
  height: 100%;
}
#landing-container {
  height: 100%;
}

#landing-container > div {
  height: 100%;
}

#login {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  margin-bottom: 28px;
  z-index: 1;
  opacity: 0.95;
}
</style>
