import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
// import GSignInButton from 'vue-google-signin-button'
import { LoaderPlugin } from 'vue-google-login';
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/fonts.css'
import './assets/style.css'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"

Vue.config.productionTip = false

//Axios
axios.defaults.baseURL = 'https://members.able.ngo'
axios.defaults.headers.get['Accepts'] = 'application/json'
const reqInterceptor = axios.interceptors.request.use(config => {
  console.info('Request Interceptor', config)
  return config
})
const resInterceptor = axios.interceptors.response.use(res => {
  console.info('Response Interceptor', res)
  return res
})

axios.interceptors.request.eject(reqInterceptor)
axios.interceptors.response.eject(resInterceptor)

// Google login
Vue.use(LoaderPlugin, {
  client_id: "386417534091-qc6fsd9h03q8e8d8cq1gqjr1sk1acjno"
});
// Vue.use(GSignInButton)
Vue.use(VueAxios, axios)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
